import React, { FC } from "react";
import Link from "next/link";
import { Row, Breadcrumb, Button } from "antd";
import { useAuthContext } from "@dewo/app/contexts/AuthContext";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import { LoginButton } from "../../auth/buttons/LoginButton";
import { useSidebarContext } from "@dewo/app/contexts/SidebarContext";
import styles from "./Header.module.less";
import { MenuOutlined } from "@ant-design/icons";
import { Logo } from "@dewo/app/components/Logo";
import { useSidebarEnabled } from "../Sidebar";

const itemRender = (route: Route, _params: any, _routes: any, paths: any) => (
  <Link href={["", ...paths].join("/")}>{route.breadcrumbName}</Link>
);

interface HeaderBreadcrumbsProps {
  routes?: Route[];
}

export const HeaderBreadcrumbs: FC<HeaderBreadcrumbsProps> = ({ routes }) => {
  const { user } = useAuthContext();
  const sidebar = useSidebarContext();
  const sidebarEnabled = useSidebarEnabled();
  return (
    <Row align="middle" justify="space-between" style={{ columnGap: 8 }}>
      {sidebarEnabled ? (
        <Button className={styles.mobileMenuButton} onClick={sidebar.toggle}>
          <MenuOutlined />
        </Button>
      ) : (
        <Link href="/">
          <a>
            <Logo />
          </a>
        </Link>
      )}

      <Row style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
        <Breadcrumb itemRender={itemRender} routes={routes} />
      </Row>
      {!user && (
        <LoginButton className="dewo-header-mobile" type="primary">
          Connect
        </LoginButton>
      )}
    </Row>
  );
};
