import React, { FC } from "react";
import { Task, TaskGatingType, TaskStatus } from "@dewo/app/graphql/types";
import { usePermission } from "@dewo/app/contexts/PermissionsContext";
import { ClaimableIcon } from "@dewo/app/components/icons/task/Claimable";
import { ApplicationIcon } from "@dewo/app/components/icons/task/Application";
import { ContestIcon } from "@dewo/app/components/icons/task/Contest";
import { LockIcon } from "@dewo/app/components/icons/task/Lock";
import { Tooltip } from "antd";

interface Props {
  task: Task;
}

export const taskGatingTooltip: Record<TaskGatingType, string> = {
  [TaskGatingType.ASSIGNEES]: "This task is already assigned to someone",
  [TaskGatingType.OPEN_SUBMISSION]:
    "Anyone can do this task and submit work. One or more get chosen as winners",
  [TaskGatingType.ROLES]:
    "Claim this task to assign yourself and start working on it",
  [TaskGatingType.APPLICATION]:
    "Apply to this task, and the task reviewer can assign it to you",
};

export const TaskGatingIcon: FC<Props> = ({ task }) => {
  const canUpdateTask = usePermission("update", task);

  if (task.status === TaskStatus.TODO) {
    if (task.gating === TaskGatingType.ASSIGNEES) {
      return (
        <Tooltip title={taskGatingTooltip[TaskGatingType.ASSIGNEES]}>
          <LockIcon style={{ width: 16 }} />
        </Tooltip>
      );
    }

    if (task.gating === TaskGatingType.OPEN_SUBMISSION) {
      return (
        <Tooltip title={taskGatingTooltip[TaskGatingType.OPEN_SUBMISSION]}>
          <ContestIcon style={{ width: 16 }} />
        </Tooltip>
      );
    }

    if (canUpdateTask && task.gating === TaskGatingType.ROLES) {
      return (
        <Tooltip title={taskGatingTooltip[TaskGatingType.ROLES]}>
          <ClaimableIcon style={{ width: 16 }} />
        </Tooltip>
      );
    }

    return (
      <Tooltip title={taskGatingTooltip[TaskGatingType.APPLICATION]}>
        <ApplicationIcon style={{ width: 16 }} />
      </Tooltip>
    );
  }

  return null;
};
